import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { getCourseNavigation } from "../../../../../store/courses"
import LayoutCourse from "../../../../../layouts/course"

import Box from "../../../../../components/box"
import Paragraph from "../../../../../components/paragraph"
import CourseFooterNext from "../../../../../components/course-footer-next"
import Heading from "../../../../../components/heading"
import Icon from "../../../../../components/icon"
import Image from "../../../../../components/image"
import ImageHotspot from "../../../../../components/image-hotspot"
import ImageHotspots from "../../../../../components/image-hotspots"
import ImageHotspotsModalTemplate from "../../../../../components/image-hotspots-modal-template"
import Note from "../../../../../components/note"
import Poem from "../../../../../components/poem"
import QuotePrimary from "../../../../../components/quote-primary"
import Seo from "../../../../../components/seo"
import Stack from "../../../../../components/stack"
import Text from "../../../../../components/text"
import Caption from "../../../../../components/caption"
import Small from "../../../../../components/small"

const Page = () => {
  const navigation = getCourseNavigation({ courseId: "haelfte-des-lebens" })

  const data = useStaticQuery(graphql`
    query ManuskriptFeiertageQuery {
      gedicht: file(relativePath: { eq: "kurse/wie-wenn-am-feiertage.png" }) {
        ...largeImage
      }
      haelfte01: file(relativePath: { eq: "kurse/haelfte-01.jpg" }) {
        ...largeImage
      }
      haelfte02: file(relativePath: { eq: "kurse/haelfte-02.jpg" }) {
        ...largeImage
      }
      haelfte03: file(relativePath: { eq: "kurse/haelfte-03.jpg" }) {
        ...largeImage
      }
      haelfte04: file(relativePath: { eq: "kurse/haelfte-04.jpg" }) {
        ...largeImage
      }
    }
  `)

  return (
    <LayoutCourse
      navigation={navigation}
      footer={
        <CourseFooterNext to="/kurse/haelfte-des-lebens/02-entstehungsgeschichte/manuskript-erforschen/gedicht" />
      }
    >
      <Seo title="Das Manuskript erforschen" />
      <Box>
        <Stack>
          <Stack space={3}>
            <Heading as="h1" level={2}>
              Das Manuskript erforschen
            </Heading>
          </Stack>
          <Paragraph>
            Die Entstehungsgeschichte von ›Hälfte des Lebens‹ reicht um einige
            Jahre vor das Datum der Erstveröffentlichung zurück. Erste
            Keimwörter tauchen bereits in einem Manuskript aus den Jahren
            1799/1800 zum ersten Mal auf...
          </Paragraph>
          <Note variant="task">
            <>
              Was kann man aus dieser Handschrift erfahren? Sieh dir das
              Manuskript genauer an und inspiziere die mit dem
              <Box
                as="span"
                display="inline-flex"
                ml={1}
                bg="primary"
                color="background"
                borderRadius="full"
              >
                <Icon size={4} icon="plus" />
              </Box>{" "}
              Zeichen gekennzeichneten Details.
            </>
          </Note>
          <Stack space={3}>
            <ImageHotspots
              image={
                <Image
                  image={data.gedicht.childImageSharp.gatsbyImageData}
                  alt="Handschriftliches Manuskript von Hälfte des Lebens"
                />
              }
            >
              <ImageHotspot x={20} y={8} name="haelfte-01">
                <ImageHotspotsModalTemplate
                  title="Keimwörter"
                  image={
                    <Image
                      image={data.haelfte01.childImageSharp.gatsbyImageData}
                      alt=""
                    />
                  }
                >
                  <Paragraph>
                    Mit dünnen Linien notiert Hölderlin auf dem Blatt zunächst
                    lediglich eine Reihe loser Stichwörter: »Die Rose«, »Die
                    Schwäne.«, »Der Hirsch.« Darunter, etwas versetzt links und
                    rechts: »Holde Schwester!« und »Edles Wild«. Solche
                    Ausgangswörter, die erst im weiteren Schreibprozess zu
                    Versen ausgearbeitet und angereichert werden, hat der
                    Hölderlin-Herausgeber Friedrich Beißner einmal als
                    »Keimwörter« bezeichnet. Sie sind typisch für Hölderlins
                    Schreib- und Arbeitsweise: Selten entsteht ein Gedicht
                    linear, meist verteilen sich zunächst einzelne Versatzstücke
                    auf dem Papier, die erst später in einen Kontext eingebettet
                    werden.
                  </Paragraph>
                </ImageHotspotsModalTemplate>
              </ImageHotspot>
              <ImageHotspot x={27} y={20} name="haelfte-02">
                <ImageHotspotsModalTemplate
                  title="trunken von / Küssen"
                  image={
                    <Image
                      image={data.haelfte02.childImageSharp.gatsbyImageData}
                      alt=""
                    />
                  }
                >
                  <Paragraph>
                    Unter dem Stichwort »Die Schwäne.« hält Hölderlin vermutlich
                    am 31. Dezember 1800, kurz bevor er im Januar 1801 für eine
                    neue Hauslehrerstelle nach Hauptwil in die Schweiz
                    aufbrechen wird, eine Traube von Worten fest. Es sind die
                    Keime des Gedichts ›Hälfte des Lebens‹:
                  </Paragraph>
                  <Paragraph>
                    »und trunken von <br />
                    Küssen taucht ihr <br />
                    das Haupt ins hei-/ <br />
                    lignüchterne kühle <br />
                    Gewässer«
                  </Paragraph>
                  <Paragraph>Und rechts darunter:</Paragraph>
                  <Paragraph>
                    »Wo nehm ich, wenn es Winter, ist <br />
                    Die Blumen, daß ich Kränze den Himmlischen <br />
                    winde? <br />
                    Dann wird es seyn, als wüßt ich nimmer von Göttlichen,{" "}
                    <br />
                    Denn{" "}
                    <Text as="span" sx={{ textDecoration: "line-through" }}>
                      wenn
                    </Text>{" "}
                    von mir sei gewichen des Lebens Geist; <br />
                    Wenn ich den Himmlischen die Liebeszeichen <br />
                    Die Blumen im{" "}
                    <Text as="span" sx={{ textDecoration: "line-through" }}>
                      nakten
                    </Text>{" "}
                    kahlen Felde suche <br />
                    u. dich nicht finde«
                  </Paragraph>
                </ImageHotspotsModalTemplate>
              </ImageHotspot>
              <ImageHotspot x={45} y={55} name="haelfte-03">
                <ImageHotspotsModalTemplate
                  title="Der Güter Gefährlichstes"
                  image={
                    <Image
                      image={data.haelfte03.childImageSharp.gatsbyImageData}
                      alt=""
                    />
                  }
                >
                  <Paragraph>
                    Nahe am Falz des Heftes hangeln sich die Wörter zu einem
                    weiteren Gedicht senkrecht untereinander. Der Gedichtentwurf
                    mit dem Titel ›Im Walde.‹ wird Fragment bleiben. Eine
                    Sequenz daraus wird jedoch zu einem der bekanntesten
                    Hölderlin-Aphorismen werden – womöglich auch deshalb, weil
                    sie Hölderlins dichterisches Selbstverständnis auf den Punkt
                    trifft. Sie lautet: »der / Güter Gefähr-/lichstes, die /
                    Sprache«.
                  </Paragraph>
                </ImageHotspotsModalTemplate>
              </ImageHotspot>
              <ImageHotspot x={20} y={50} name="haelfte-04">
                <ImageHotspotsModalTemplate
                  title="Wie wenn am Feiertage..."
                  image={
                    <Image
                      image={data.haelfte04.childImageSharp.gatsbyImageData}
                      alt=""
                    />
                  }
                >
                  <Paragraph>
                    In der Mitte des Blattes macht sich der Entwurf zu einer
                    Hymne breit. Es ist Hölderlins erster und einziger Versuch,
                    eine Hymne streng nach dem Vorbild Pindars zu dichten –
                    nämlich regelmäßig gebaut, triadisch gegliedert und mit
                    silbengenauer metrischer Entsprechung. Dort heißt es:
                  </Paragraph>
                  <QuotePrimary>
                    <Poem>
                      <p>Und sag ich gleich</p>
                      <p>Ich sei genaht, die Himmlischen zu schauen,</p>
                      <p>
                        Sie selbst, sie werfen mich tief, unter die Lebenden
                      </p>
                      <p>Den falschen Priester, ins Dunkel hinab</p>
                    </Poem>
                  </QuotePrimary>
                  <Paragraph>
                    Der Versuch wird auch nach erneuten Umarbeitungen Fragment
                    bleiben. Er wird später den Arbeitstitel ›Wie wenn am
                    Feiertage...‹ – nach den ersten Worten des Gedichts –
                    erhalten.
                  </Paragraph>
                </ImageHotspotsModalTemplate>
              </ImageHotspot>
            </ImageHotspots>
            <Stack space={1}>
              <Caption>
                Manuskript aus der Württembergischen Landesbibliothek
              </Caption>
              <Small
                color="whisper"
                align="center"
                sx={{ wordBreak: "break-all" }}
              >
                Cod.poet.et.phil.fol.63,I,6
              </Small>
            </Stack>
          </Stack>
        </Stack>
      </Box>
    </LayoutCourse>
  )
}

export default Page
